@import "../../styles/theme";


.root {
  padding: 5px 15px;
  border: none;
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: transparent;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
}

.outlined {
  border: 1px solid;
  padding: 4px 14px;
  &.primary {
    border-color: $primary;
  }
  &.secondary {
    border-color: $secondary;
  }
}

.contained {
  &.primary {
    background-color: $primary;
    &:hover {
      border: 1px solid $primary;
    }
  }
  &.secondary {
    background-color: $secondary;
    &:hover {
      border: 1px solid $secondary;
    }
  }
}

.text {
  border: none;
}

.primary {
  color: $primary;
  &.contained {
    color: white;
  }
}

.secondary {
  color: $secondary;
  &.contained {
    color: white;
  }
}

.fullWidth {
  width: 100%;
}

.disabled {
  background-color: rgb(0 0 0/0.3);
  pointer-events: none;
}