@import "ui/src/styles/theme";

.root {
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  padding: spacing(4);
  border: 1px solid $primary;
  border-radius: 4px;
}

.startAdornment {
  position: absolute;
  top: 20%;
  left: spacing(2);

  & * {
    padding: 0;
    width: 24px;
    height: 24px;
  }

  & + .input {
    padding-left: spacing(10);
  }
}

.endAdornment {
  position: absolute;
  top: 20%;
  right: spacing(4);

  .clearBtn {
    padding: 0;
    width: 24px;
    height: 24px;
    svg {
      color: $primary;
    }
  }
}

.options {
  position: absolute;
  width: 100%;
  z-index: 1;
  background: white;
  margin: spacing(4) 0 0;
  padding: 0;
  box-shadow: $elevation-1;
  border-radius: 4px;
  max-height: 420px;
  overflow: auto;
}

.option {
  list-style: none;
  padding: spacing(2);
  transition: all ease-in-out 0.2s;
  &:hover,
  &.active {
    cursor: pointer;
    color: $primary;
    font-weight: bolder;
    background-color: $primary-background;
  }
}
