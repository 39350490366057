@import "ui/src/styles/theme";


.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

.aside {
  display: none;
  border-right: 1px solid  rgba(0, 0, 0, 0.1);
  padding: 8px;
  padding-top: 0;
  min-width: 232px;
}

.main {
  flex: 1;
  overflow: auto;
  width: 100%;
  padding-top: spacing(4);
  padding-bottom: 62px;
}

.content {
  width: 100%;
  padding: 0;
}

.playground {
  width: 100%;
  flex-direction: column;
}

@include media-breakpoint-up(sm) {
  .content{
    max-width: 750px;
  }
}
@include media-breakpoint-up(lg) {
  .content{
    max-width: 970px;
  }
}

@include media-breakpoint-up(md) {
  .main {
    padding: 0;
  }

  .aside {
    display: block;
  }

  .content {
    padding: spacing(4);
  }
}
