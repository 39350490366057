@import "ui/src/styles/theme";

$drawer-item-color: rgba(255, 255, 255, 0.85);


.root {
  position: relative;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.container {
  height: 60px;
  align-items: center;
  max-width: 750px;
}

.brandWrapper {
  display: flex;
  align-items: center;
}

.mobileMenu {
  display: flex;
}

.navItem.navSeparator {
  display: inline-block;
  height: 16px;
  width: 1px;
  background-color: $background;
  margin: auto 0;
  padding: 0;
}

.navAction {
  height: 48px;
  width: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: spacing(1);
  margin: spacing(0, 1);
  transition: all 0.5s;
  background-color: white;
  color: white;
  border-radius: 10px;
  border: 1px solid #eeeeee;

  &:hover {
    background-color: #fafafa;
    border-color: #e0e0e0;
    box-shadow: 0 0 20px #eee;
  }

  svg {
    fill: $primary;
  }
}

.drawerLangSwitcherButton {
  color: $drawer-item-color;
  padding: 0;
}

.searchVerbCTA {
  svg {
    width: 28px;
    height: 28px;
  }
  span {
    display: none;
  }
}

.youtubeCta svg {
  fill: red;
  width: 28px;
  height: 28px;
}

.navItem {
  margin: spacing(0, 2);
  display: block;
  @include mat-typo(14, $font-weight-bold, .25);
}

.link {
  text-transform: capitalize;
  color: black;
  &:hover {
    color: $primary
  }
}

nav.desktop {
  display: none;
  margin-left: spacing(2);
  & ul {
    display: inline-flex;
    align-items: center;
  }
}

.clear {
  margin-left: auto;
}

.drawerMenu {
  padding: spacing(5);
}


.drawerMenuItem {
  margin-bottom: spacing(2);
  & a {
    color: $drawer-item-color;
    font-weight: $font-weight-medium;
    text-transform: capitalize;
    &:hover {
      color: white;
    }
  }
}

.drawerYoutubeCTA a {
  text-transform: none;
}

.drawerDivider {
  width: 100%;
  height: 1px;
  margin: spacing(6, 0);
  background-color: $primary-light;
}

@include media-breakpoint-up(md) {
  .mobileMenu {
    display: none;
  }

  .youtubeCta {
    @include mat-typo(14, $font-weight-regular, .25);
    svg {
      fill: red;
      width: 28px;
      height: 28px;
    }
  }

  .brandWrapper button {
    display: none;
  }

  .navAction {
    height: 34px;
    width: 34px;
    padding: spacing(2);
  }
  nav.desktop {
    display: block;
  }
  .searchVerbCTA {
    width: 150px;
    justify-content: start;
    color: $primary;
    @include mat-typo(14  , $font-weight-medium, 1);
    span {
      display: inline;
      margin-left: spacing(1);
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .brandWrapper {
    width: 150px;
  }

  .container {
    height: 80px;
    max-width: 970px;
  }

  .navItem {
    @include mat-typo(16, $font-weight-bold, .25);
  }

  .brandIcon {
    transform: scale(1.5);
  }
}
