@import "../../styles/theme";

.root {
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  padding: 0;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
}

.closeBtn {
  position: absolute;
  top: spacing(2);
  right: spacing(2);
  padding: spacing(1);
  & svg {
    height: 32px;
    width: 32px;
    fill: $primary-dark;
  }
  &:hover svg {
    transition: fill 0.1s;
    fill: $primary-light;
  }
}

@include media-breakpoint-up(sm) {
  .root {
    padding: spacing(10, 10, 5, 10);
  }

  .content {
    border-radius: 8px;
    box-shadow: $elevation-1;
    max-width: 750px;
  }
}
