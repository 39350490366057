@import "ui/src/styles/theme";


.root {
  width: 100%;
  padding: spacing(4);
  border-radius: 8px;
  text-align: center;
  background-color: $primary-light;
  &:hover {
    cursor: text;
  }
}
