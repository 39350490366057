@import "ui/src/styles/theme";

.root {
  position: relative;
}

.menuItems {
  min-width: 90px;
  @include mat-typo(14, $font-weight-regular, 1);
}

.active {
  color: $primary;
  font-weight: $font-weight-medium;
}
