@import "ui/src/styles/theme";

.root {
  display: block;
  height: 550px;
  width: 100vw;
  position: fixed;
  z-index: 999;
  top: -1150px;
  background-color: $primary-dark;
  transition: all ease-out 0.3s;
  &.open {
    transform: translateY(1150px);
  }
}

.closeBtn {
  position: absolute;
  width: 32px;
  height: 32px;
  top: spacing(2);
  right: spacing(2);
  color: rgba(255, 255, 255, 0.7);
  font-weight: bolder;
  &:hover {
    color: white;
    transition: color ease-in-out .1s;
  }
}

@include media-breakpoint-up(md) {
  .root {
    display: none;
  }
}
