@import "typography";

// Colors

$primary: #544a90;
$primary-light: rgba(127, 123, 255, 0.18);
$primary-dark: #252262;
$primary-background: #fbfbfb;
$secondary: #be1e2e;
$secondary-light: #f75857;
$secondary-dark: #860006;
$background: rgba(84,74,144,.05);


$elevation-1: 0 0 20px rgba(84, 74, 144, 0.16);

// Breakpoints

$breakpoints: (
  'sm':  576px,
  'md': 768px,
  'lg':  1024px,
  'xl': 1200px,
) !default;

@mixin media-breakpoint-up($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
 // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Spacing

$default-spacing: 4;

@function multiply-spacing($value: 1) {
  @return $default-spacing * $value;
}

@function format-spacing($value: 1) {
  @if ($value == 'auto') {
    @return 'auto';
  }
  @return multiply-spacing($value)+px;
}

@function spacing($top: 1, $right: null, $bottom: null, $left: null) {
  @if ($top and $right and $bottom and $left) {
    @return format-spacing($top) + ' ' + format-spacing($right) + ' ' + format-spacing($bottom) + ' ' + format-spacing($left);
  } @else if ($top and $right and $bottom and not $left) {
    @return format-spacing($top) + ' ' + format-spacing($right) + ' ' + format-spacing($bottom) + ' ' + format-spacing(0);
  } @else if ($top and $right and not $bottom and not $left) {
    @return format-spacing($top) + ' ' + format-spacing($right);
  } @else if ($top and not $right and not $bottom and not $left) {
    @return format-spacing($top);
  }
}

// Border

$border-radius: format-spacing(2);
$divider: 1px solid $background;
