@import "../../styles/theme";

.root {
  color: black;
  padding-left: 0;
}

.icon {
  display: block;
  height: 3px;
  width: 26px;
  margin-top: 8px;
  background-color: $primary;
  &:nth-child(2) {
    margin-top: 0;
  }
  &:nth-child(3) {
    width: 14px;
  }
}
