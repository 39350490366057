@import "ui/src/styles/theme";

.root {
  width: fit-content;
  padding: 0.25rem 0.5rem;
  padding-right: 1.75rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
  background-color: $primary;
  border-radius: 1rem;
}

.text {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25;
}

.action {
  position: absolute;
  top: 0;
  right: 0.2rem;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  color: white;
  svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  &:hover {
    cursor: pointer;
  }
}