@import "ui/src/styles/theme";

.root {
  display: inline-block;
}

.source {
  display: block;
}

.translation {
  display: block;
  color: $primary-dark;
  font-weight: 500;
}
