@import "ui/src/styles/theme";

.root {
  width: 24px;
  height: 24px;
  display: block;
  margin: 15px auto;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.root::after,
.root::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  border-radius: 50%;
  animation: animLoader 1s infinite ease-in-out;
}
.root::before {
  transform: scale(0.5) translate(-200%, -200%);
}

.root {
  &.primary {
    color: $primary;
    &::after, &::before {
      background-color: $primary;
    }
  }
  &.secondary {
    color: $secondary;
    &::after, &::before {
      background-color :$secondary;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animLoader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}
