@import "ui/src/styles/theme";

$drawer-item-color: rgba(255, 255, 255, 0.85);

.root {
  flex-direction: column;
  height: 100vh;
  position: relative;
}

.heading {
  color: $primary-dark;
  margin: 16px 0;

 &.dark {
    color: $drawer-item-color;
    @include mat-typo(20, $font-weight-medium, .25);
    &::first-letter {
      text-transform: uppercase;      
    }
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.href {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  border-radius: 8px;
  color: $primary-dark;
  transition: background-color 0.3s;
  
  &.dark {
    color: $drawer-item-color;
    font-weight: $font-weight-medium;
    text-transform: capitalize;
  }

  &:hover {
    background-color: $primary-light;
  }
}


.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin-right: 8px;

  &.dark {
    color: rgba(255, 255, 255, 0.6);
    background-color: white;
    border-radius: 100%;
  }
}

.footer {
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.6);

  &.dark {
    color: rgba(255, 255, 255, 0.6);
  }
}

.dark {
  color: white;
}
