@import "../../styles/theme";

.root {
  padding: spacing(2, 4);
  border-bottom: $divider;
  &:hover {
    cursor: pointer;
    background-color: $background;
  }
  &:last-of-type {
    border-bottom: none;
  }
}
