@import "ui/src/styles/theme";

.root {
  display: flex;
  flex-direction: column;
}

.inner {
  position: relative;
}

.button {
  position: relative;
  width: 100%;
  border: 1px solid $primary;
  border-radius: 10px;
  text-align: left;
  padding-right: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0/0.1),0 2px 4px -2px rgb(0 0 0/0.1);
  background-color: transparent;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
  }
}

.placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  padding-right: 0.5rem;
  align-items: center;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;

  .chevronIcon {
    height: 1.25rem;
    width: 1.25rem;
    color:rgb(156 163 175/0.9)
  }
}

.transitionLeave {
  transition: all ease-in 0.1;
}
.transitionLeaveFrom {
  opacity: 1;
}
.transitioonLeaveTo {
  opacity: 0;
}

.options {
  position: absolute;
  margin-top: 0.25rem;
  max-height: 15rem;
  width: 100%;
  overflow: auto;
  border-radius: 10px;
  background-color: white;
  padding: 0.25rem 0;
  z-index: 1;
  box-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
}

.option {
  position: relative;
  user-select: none;
  padding: 0.5rem;
  color: rgb(17 24 39/0.9);
  background-color: white;
  &::first-letter {
    text-transform: uppercase;
  }
}

.optionText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.optionActive {
  color: $primary-dark;
  background-color: $primary-light;
  cursor: pointer;
}

.optionSelected {
  font-weight: bolder;
  padding-left: 2rem;
}

.checkMark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  color: $primary-dark;
  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
}

.selectedContainer {
  margin: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
}
