/* Load google's Roboto font*/
/* More information can be found here */
/* @see https://material.io/design/typography/#type-scale*/
/* @see https://gist.github.com/amlang/c82496a3b36050616e99963d23c5d291 */
/* @see https://material.io/ */
/* @see https://github.com/material-components/material-components-web/blob/master/LICENSE */


/*Conversion Ration sp to rem for html */
$conversionRatioSpToRemWeb: 1/16; //! 0.0625;
$conversionRatioRemToPxWeb: 16.0; 
// Scales
@function spToRem($sps){
  @return $sps * $conversionRatioSpToRemWeb;
};
@function remToPx($rems){
  @return $rems * $conversionRatioRemToPxWeb;
};
@function spacing($tracking, $px){
  @return $tracking / $px;
};
@mixin mat-typo($size, $weight, $spacing) {
  font-weight:  $weight;
  font-size: #{spToRem($size)}rem;
  letter-spacing: #{spacing($spacing, remToPx($size))}px;
}
// Font

$font-family: "Roboto", 'Helvetica Neue, Helvetica, Arial';

// Weights
$font-weight-thin:    100;
$font-weight-light:   300;
$font-weight-regular: 400;
$font-weight-medium:  500;
$font-weight-bold:    700;
$font-weight-black:   900;

.h1 {  @include mat-typo(96, $font-weight-light, -1.5);  }
.h2 {  @include mat-typo(60, $font-weight-light, -.5);   }
.h3 {  @include mat-typo(48, $font-weight-regular, 0);   }
.h4 {  @include mat-typo(34, $font-weight-regular, .25); }
.h5 {  @include mat-typo(24, $font-weight-regular, 0);   }
.h6 {  @include mat-typo(20, $font-weight-medium, .15);  }

.subtitle1 { @include mat-typo(16, $font-weight-regular, .15); }
.subtitle2 { @include mat-typo(14, $font-weight-medium, .1);   }
.body1     { @include mat-typo(16, $font-weight-regular, .5);  }
.body2     { @include mat-typo(14, $font-weight-regular, .25); }
.button {
  @include mat-typo(16, $font-weight-medium, .75);
}
.caption {  @include mat-typo(14, $font-weight-regular, .4); }
.overline {
  @include mat-typo(10, $font-weight-regular, 1.5);
  text-transform: uppercase;
}


