@import "ui/src/styles/theme";

.searchIcon {
  margin-top: 6px;
  fill: $primary;
}

.escButton {
  align-self: center;
  cursor: pointer;
  height: 1.5rem;
  margin-top: 6px;
  margin-right: 10px;
  padding: 3px 8px 6px;
  border-radius: 5px;
  background-color: rgb(243, 246, 249);
  border: 1px solid rgb(205, 210, 215);
  font-size: 0.75rem;
  letter-spacing: 0.08rem;
  font-weight: 700;
  color: rgb(62, 80, 96);
}

.modal {
  & .input {
    padding-top: spacing(5);
    padding-bottom: spacing(5);
    border: none;
    border-radius: 0;
    border-bottom: 1px #eee solid;
  }

  & .options {
    height: calc(100vh - 124px);
    margin: 0;
    border-radius: 0;
    box-shadow: unset;
    overflow: auto;
  }
  &.open {
    display: block;
  }

  .modalContent {
    padding: 0;
    overflow: hidden;
  }
}

@include media-breakpoint-up(md) {
  .modal {
    padding: 100px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .modalContent {
    max-height: 525px;
    padding: spacing(10);
  }

  .options {
    max-height: 465px;
  }
}
