@import "ui/src/styles/theme";

.root {
    position: fixed;
    bottom: 0;
    display: flex;
    height: 60px;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: $primary-dark;
}

.listItem {
  width: 28px;
  height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & a {
    color: rgba(255, 255, 255, 0.9);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
}


.icon {
  height: 28px;
  width: 28px;
  margin-bottom: spacing(1);
}

@include media-breakpoint-up(sm) {
  .root {
    display: none;
  }
}
