@charset "UTF-8";
@import "normalize";
@import "layout";
@import "theme";

html, :root {
  font-family: $font-family;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $primary;
}

.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
