@import "../../styles/theme";

.root {
  position: absolute;
  margin-top: spacing(1);
  border-radius: spacing(1);
  box-shadow: $elevation-1;
  background-color: white;
  z-index: 1;
}
